$font-family-base: 'Roboto', sans-serif;//Familia tipográfica base
$line-height-base: 130%;//Line height base
$font-size-base: 14;//Tamaño base en pixeles
$padding-base: #{(10 / $font-size-base)}rem;

$link-color: #276AA6;//Color del link principal
$link-hover-color: darken($link-color, 15%);
// $body-bg: #F4F4F7;//Fondo del body
$body-bg:   #dbdcdb ;//Fondo del body

$h1-size: #{(21 / $font-size-base)}rem;
$h2-size: #{(18 / $font-size-base)}rem;
$h3-size: #{(16 / $font-size-base)}rem;
$h4-size: #{(14 / $font-size-base)}rem;
$h5-size: #{(13 / $font-size-base)}rem;
$h6-size: #{(12 / $font-size-base)}rem;

$margin: 20px; //Margen general

/*----Colores----*/
$titles: #011F56;
$navigation-titles: #113758;
$text-color: #606060;
$light-text-color: #4A4A4A;
$lighter-text-color: #919191;
$imperceptible-text-color: #A7A7A7;
$not-found-text-color: #9CA0B0;

$table-header-color: #B9C3D0;

$primary: #276AA6;
$secondary: #D4E8FA;

$light-primary: #5393CC;

$border-color: #C3C3C3; 
$input-border-color: #919191;

$table-button-bg: #F5F5F5;

$red: #F73742;
$light-red: #FDDDDF;
$green: #38C14F;
$light-green: #EAFFED;
$yellow: #FAAF00;
$light-yellow: #FCF6EA;

$percent-bar-color: #79B1E3;
$percent-bar-completed-color: #37C3F7;

/*----Breakpoints----*/
$breakpoints: (
  xlarge: "(min-width : 1300px)",
  large: "(min-width: 1024px) and (max-width : 1300px)",
  medium: "(min-width: 768px) and (max-width:1024px)",
  small: "(max-width : 767px)"
);