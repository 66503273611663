/*------------------------------------------------------------------------------Variables */
@import 'variables';
/*------------------------------------------------------------------------------Datepicker */
@import 'datepicker';
/*------------------------------------------------------------------------------Draft */
@import 'mention-plugin';
/*------------------------------------------------------------------------------Animaciones */
@import 'animate';
/*------------------------------------------------------------------------------Normalizador */
@import 'normalize';
/*------------------------------------------------------------------------------Mixins */
@import 'mixins';

/*------------------------------------------------------------------------------Grillas */
.row {width: 100%; margin-left: auto; margin-right: auto;}

//Generador de grilla
@include grid-generator(12, 20, 1450, grid);
/*------------------------------------------------------------------------------Auxiliares */
.to-the-left {float: left;}
.to-the-right {float: right;}

.align-left {text-align: left}
.align-center {text-align: center}
.align-right {text-align: right}

.relative-position {position: relative;}

.no-gutter-left{
  padding-left: 0 !important
}

.no-gutter-right{
  padding-right: 0 !important
}

.d-flex{
  display: flex;
}
.w-50{
  width: 50%;
}

.inline-list {
  display: table;
  width: 100%;
  li {
    display: table-cell;
    vertical-align: middle;
  }
  &.floated {
    display: block;
    @extend .clearfix;
    li {
      display: block;
      float: left;
    }
  }
}

.vertical-list{
  display: block;
  li{
    display: block;
    a{
      display: block;
      padding: 10px;
    }
  }
}

.user-list{
  background-color: #FFF;
  margin: 0;
  padding: 0;
  border-top: 1px solid $border-color;
  li{
    display: block;
    position: relative;
    padding: 20px;
    border-bottom: 1px solid $border-color;
    font-size: #{(12 / $font-size-base)}rem;
    &:last-child{
      border-bottom: 0;
    }
  }
}

.user-name{
  display: block;
  font-weight: bold;
}

.user-indicators{
  position: absolute;
  bottom: 20px;
  right: 10px;
}

//Clases para realizar animaciones con delay
@for $i from 0 through 11 {
  .delay-animation.delay-#{$i} {
    animation-delay: ($i * 50ms);
  }
}

@for $i from 0 through 5{
  .m-r-#{$i * 10}{
    margin-right: ($i * 10px);
  }
  .m-l-#{$i * 10}{
    margin-left: ($i * 10px);
  }
  .m-b-#{$i * 10}{
    margin-bottom: ($i * 10px);
  }
  .m-t-#{$i * 10}{
    margin-top: ($i * 10px);
  }
  .p-r-#{$i * 10}{
    padding-right: ($i * 10px);
  }
  .p-l-#{$i * 10}{
    padding-left: ($i * 10px);
  }
  .p-b-#{$i * 10}{
    padding-bottom: ($i * 10px);
  }
  .p-t-#{$i * 10}{
    padding-top: ($i * 10px);
  }
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.button-reset {
  @include appearance(none);
  background: transparent;
  border-radius: 0px;
  border: 0px;
  outline: 0px;
  padding: 0px;
}

.elastic-img {
  width: 100%;
  height: auto;
}

.elastic-img-limit {
  max-width: 100%;
  height: auto;
}

.centered-img {
  display: block;
  margin: 0 auto;
}

.clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.rounded-img {
  border-radius: 100%;
  border: 1px solid $border-color;
}

.clear-margin {
  margin: 0;
}

.margin-bottom-wrapp {
  margin-bottom: 20px;
  &.margin-bottom-wrapp-big{
    margin-bottom: 40px;
  }
}

.container{
  padding-left: 10px;
  padding-right: 10px;
}

.ohidden{
  overflow: hidden;
}

.hide{
  display: none;
}

.wrapper{
  padding: 20px;
  &.wrapper-vertical{
    padding-left: 0;
    padding-right: 0;
  }
  &.wrapper-horizontal{
    padding-top: 0;
    padding-bottom: 0;
  }
}

.box-wrapp{
  padding: $padding-base;
  @include respond-to('small'){
    padding: 10px;
  }
}

.disabled{
  opacity: 0.3;
  pointer-events: none;
}

.disable-interaction{
  pointer-events: none;
}

.inline-right{
  margin-left: 10px;
}

.inline-left{
  margin-right: 10px;
}

.inline-element{
  display: inline-block;
}

.block-element{
  display: block;
}

.responsive-table-holder{
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    min-height: 200px;
}

.only-on-small{
  display: none !important;
  @include respond-to('small'){
    display: block !important;
  }
}

.full-height{
  height: 100vh;
}

.vertically-center-parent{
  position: relative;
  height: 100%;
}

.vertically-center-children{
  top: 50%;
  transform: translate3d(0,-50%,0);
  position: relative;
}

.bordered-left{
  border-left: 1px solid $border-color;
}

.bordered-bottom{
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $border-color;
  &.no-margin{
    margin-bottom: 0;
  }
  &:last-child{
    border: 0 none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.min-box{
  min-height: 510px;
}
/*------------------------------------------------------------------------------Generales */
::-webkit-scrollbar { 
  display: none; 
}

html {
  font-size: #{$font-size-base}px;
}

body {
  font-family: $font-family-base;
  line-height: $line-height-base;
  background-color: $body-bg;
  font-size: #{$font-size-base}px;
  -webkit-backface-visibility: hidden;
  color: $text-color;
  &.preload{
    overflow: hidden;
  }
}

button {
  font-family: $font-family-base;
}

a {
  color: $link-color;
  text-decoration: underline;
  outline: 0 none;
  transition: all 350ms ease;
  &:hover {
    color: darken($link-color, 15%);
    outline: 0 none;
    text-decoration: none;
  }
  &:focus {
    outline: 0 none;
  }
}

ul {
  margin-left: 0;
}

p {
  font-size: 1rem;
  margin: 0;
  line-height: $line-height-base;
  margin-bottom: $margin;
  &:last-child {
    margin-bottom: 0;
  }
}

iframe{
  width: 100%;
}

.email-iframe {
  border: 0 none;
  height: calc(100vh - 230px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.campaign-email-iframe {
  border: 1px solid #DDD;
  height: calc(100vh - 230px);
}

hr{
  width: 100%;
  margin: 30px 0;
}

dl{
  &:last-child{
    border-bottom: 0 none;
  }
  dt, dd{
    display: inline-block;
    padding: 20px;
    margin: 0;
    border-bottom: 1px solid $border-color;
  }
  dt{
    width: 20%;
    text-align: right;
    font-weight: bold;
    padding-right: 5px;
  }
  dd{
    width: 80%;
    padding-left: 5px;
  }
}

.line-list{
  list-style: none;
  border-bottom: 1px solid $border-color;
  &.no-horizontal-padding{
    li{
      padding-left: 0;
      padding-right: 0;
    }
  }
  &:last-child{
    border-bottom: 0 none;
  }
  li{
    padding: 20px;
    border-bottom: 1px solid $border-color !important;
    &:last-child{
      border: 0 none !important;
    }
  }
}

.definition, .term{
  display: inline-block;
  vertical-align: top;
}

.term{
  font-weight: bold;
  width: 30%;
  padding-right: 10px;
  font-size: #{(12 / $font-size-base)}rem;
}

.definition{
  width: 70%;
  font-size: #{(12 / $font-size-base)}rem;
}
/*------------------------------------------------------------------------------Fondos */

/*------------------------------------------------------------------------------Headlines */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  color: $titles;
  line-height: 120%;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

h1 {font-size: $h1-size}
h2 {font-size: $h2-size}
h3 {
  font-size: $h3-size;
  color: $text-color;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 20px;
}
h4 {
  font-size: $h4-size;
  color: $text-color;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 50px;
}
h5 {font-size: $h5-size}
h6 {font-size: $h6-size}

.inner-content-title{
  margin-bottom: 0;
  text-transform: none;
}

.inner-content-subtitle{
  display: block;
  color: $imperceptible-text-color;
  font-size: #{(16 / $font-size-base)}rem;
}

.fallback-message-holder{
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.fallback-message{
  padding: 20px;
  font-weight: 500;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.small-text-disclaimer{
  font-weight: bold;
  font-size: #{(12 / $font-size-base)}rem;
  &.warning{
    color: $yellow;
  }
  &.success{
    color: $green;
  }
  &.error{
    color: $red;
  }
  &.inactive{
    color: $text-color;
  }
}

.secondary-data{
  color: $border-color;
  display: block;
  padding: 5px 0;
  font-size: #{(12 / $font-size-base)}rem;
  font-weight: 500;
}

.confirm-title{
  color: $primary;
  font-size: 14px;
  text-decoration: underline;
  font-weight: normal;
  position: absolute;
  top: 0;
  right: 0;
}

.text-green{
  color: $green;
}

.text-yellow{
  color: $yellow;
}

.text-red{
  color: $red;
}
/*------------------------------------------------------------------------------Buttons */
.btn {
  @extend .button-reset;
  font-family: $font-family-base;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  font-size: #{(12 / $font-size-base)}rem;
  padding: 15px 50px;
  width: auto;
  height: auto;
  line-height: 120%;
  transition: background 300ms ease;
  color: #F2F2F2;
  transition: all 350ms ease;
  background-color: $primary;
  font-weight: 500;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
	&.btn-small{
		padding: 12px 25px;
    background-color: #36bccd ;
    margin-right: 19px;
  }
  &.btn-smallest{
    padding: 10px 15px;
		text-transform: none;
		letter-spacing: 0
  }
	&.btn-top-right{
		position: absolute;
		top: 15px;
		right: 30px;
	}
	&.btn-secondary{
		// background-color: #D4E8FA;
    background-color: #36bccd ;
		// color: $primary;
    color:  white;
		&:hover{
			color: #FFF;
		}
  }
  &.btn-error{
    background-color: $light-red;
		color: $red;
		&:hover{
      background-color: $red;
      color: #FFF;
		}
  }
  &.btn-full{
    width: 100%;
  }
  &.btn-tab{
    background-color: white;
    // border: 1px solid $border-color;
    border: 1px solid  #C3C3C3;
    border-radius: 5px 5px 0 0;
    color: $border-color;
    // color:#3d3d3d;
    padding: 10px 40px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: bold;
    border-right: 0 none;
    position: relative;
    &.active{
      // background-color: $body-bg;
      background-color: white;
      color: $primary;
      &:after{
        content: "";
        width: 100%;
        height: 2px;
        background-color: $body-bg;
        position: absolute;
        bottom: -2px;
        display: block;
        left: 0;
        z-index: 1;
      }
    }
    &:last-child{
      border-right: 1px solid $border-color;
    }
    &:hover{
      color: $primary;
      background-color: $body-bg;
    }
  }
  &.btn-user-navigation{
    background-color: transparent;
    color: $border-color;
    font-weight: normal;
    letter-spacing: none;
    font-size: 1rem;
    text-transform: none;
    padding: 15px 25px;
    &.active{
      color: $primary;
      height: 100%;
      border: 1px solid $border-color;
      border-radius: 5px 5px 0px 0px;
      position: relative;
      &:after{
        content: "";
        height: 8px;
        background-color: #FFF;
        bottom: -4px;
        width: 100%;
        left: 0px;
        display: block;
        position: absolute;
        z-index: 2;
      }
    }
    &:hover{
      background-color: transparent;
      color: $primary;
    }
  }
  &.btn-user-logout{
    @extend .button-reset;
    background-image: url('../images/logout.svg');
    background-repeat: no-repeat;
    background-position: center center;
    padding: 8px 25px;
    margin-right: 10px;
    &:hover{
      background-color: transparent;
    }
  }
  &.btn-table{
    background-color: $table-button-bg;
    color: $primary;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      background-color: darken($table-button-bg, 15%);
    }
  }
  &.btn-user-actions{
    background-color: transparent;
    padding: 5px;
    &:hover{
      background-color: transparent;
    }
    &.active{
      border: 1px solid $border-color;
      border-radius: 5px 5px 0 0;
      border-bottom: 0 none;
      background-color: #FFF;
    }
  }
  &.btn-icon{
    background-color: transparent;
    border: 0 none;
    padding: 0;
    &:hover{
      background-color: transparent;
      opacity: 0.8;
    }
  }
  &.btn-back{
    display: inline-block;
    margin-right: 10px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    margin-top: 7px;
  }
  &.btn-link{
    color: $primary;
    font-size: 1rem;
    text-decoration: underline;
    background-color: transparent;
    width: auto;
    text-transform: none;
    letter-spacing: 0px;
    font-weight: normal;
    &:hover{
      color: darken($primary, 15%);
      text-decoration: none;
      background-color: transparent;
    }
  }
  &.rounded-btn{
    border-radius: 100%;
    background-color: #FFF;
    border: 1px solid $border-color;
    text-align: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-right: 13px;
    &.active{
      border-color: $yellow;
      background-color: $light-yellow;
    }
    &:hover{
      background-color: darken( #FFF, 5%);
    }
  }
  &:hover{
    background-color: darken($primary, 5%);
  }
}
/*------------------------------------------------------------------------------Tooltip */
.tooltip{
  position: relative;
  display: inline-block;
  margin-left: 10px;
  &.in-field{
    position: absolute;
    bottom: 10px;
    right: 0;
  }
  &:hover{
    .tooltip-content{
      opacity: 1;
      visibility: visible;
    }
  }
}

.tooltip-content{
  opacity: 0;
  visibility: hidden;
  transition: all 350ms ease;
  position: absolute;
  background-color: $light-primary;
  color: #FFF;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
  bottom: calc(100% + 10px);
  left: calc(50% - 100px);
  width: 200px;
  text-align: center;
  font-size: #{(12 / $font-size-base)}rem;
  font-weight: normal;
  z-index: 9;
  &.small{
    width: auto;
    padding: 0px 10px;
    font-size: #{(10 / $font-size-base)}rem;
  }
  &.up-left{
    bottom: calc(100% + 10px);
    left: auto;
    right: 4px;
    width: auto;
    padding: 0px 10px;
    border-radius: 5p 5px 0 5px;
    &:after{
      right: 4px;
      left: auto;
    }
  }
  &:after{
    @include triangle('down', 10px, 5px, $light-primary);
    position: absolute;
    top: 100%;
    left: calc(50% - 5px);
  }
  .error &{
    background-color: $red;
    &:after {
      @include triangle('down', 10px, 5px, $red);
    }
  }
}

/*------------------------------------------------------------------------------Simbology */

.simbology-holder{
  position: absolute;
  top: 0;
  right: 0;
}

.simbology{
  display: inline-block;
  font-weight: normal;
  font-size: #{(14 / $font-size-base)}rem;
  margin-left: 20px;
}

.simbology-color{
  background-color: gray;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  display: inline-block;
}

/*------------------------------------------------------------------------------Charts */
.chart-holder{
  position: relative;
  min-height: 325px;
}

.charts-pie{
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  
  .chart-info{
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 70px);
    width: 140px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 0;
    h4{
      margin: 0;
    }
    p{
      font-size: 10px;
      margin-bottom: 10px;
    }
  }
}

.recharts-cartesian-grid-horizontal{
  line{
    stroke: #F0F0F0;
    &:first-child{
      stroke: #C3C3C3;
      stroke-width: 2px;
    }
  }
}

.recharts-cartesian-axis-tick {
  text{
    fill: #C3C3C3;
    font-size: 12px;
    font-weight: 500;
  }
}

.recharts-cartesian-axis-tick-line{
  fill: #C3C3C3;
}

.recharts-default-tooltip{
  fill: #C3C3C3;
}

.recharts-line-curve{
  stroke-width: 2;
}

.recharts-label{
  fill: #C3C3C3;
  font-size: 12px;
}

.chart-vertical{
  .recharts-cartesian-grid-horizontal{
    line:first-child{
      stroke: #F0F0F0;
      stroke-width: 1px;
    }
  }
}
.recharts-tooltip-wrapper{
  z-index: 5;
}
/*------------------------------------------------------------------------------Icons */
.icon-element-in {
  @extend .button-reset;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-image: url('../images/sprites-3.svg');
  background-repeat: no-repeat;
  background-position: center center;
  speak: none;
  left: 15px;
  top: 15px;
  &.info{
    background-position: -134px -26px;
    width: 150px;
    height: 30px;
  }
  &.rounded{
    border-radius: 20%;
  }
}

.icon-element {
  @extend .button-reset;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-image: url('../images/sprites-3.svg');
  background-repeat: no-repeat;
  background-position: center center;
  speak: none;
  /*------------------Generales----*/
  &.inline-left {
    margin-right: 5px;
  }
  &.inline-right {
    margin-left: 5px;
  }
  &.centered{
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  &.check{
    background-position: -8px -6px;
    width: 20px;
    height: 20px;
  }
  &.edit{
    background-position: -11px -412px;
    width: 12px;
    height: 12px;
  }
  &.close{
    background-position: -143px -412px;
    width: 11px;
    height: 11px;
  }
  &.disabled-check{
    background-position: -72px -6px;
    width: 20px;
    height: 20px;
  }
  &.info{
    background-position: -134px -6px;
    width: 20px;
    height: 20px;
  }
  &.rounded{
    border-radius: 100%;
  }
  &.red{
    background-color: $red;
  }
  &.light-blue{
    background-color: $light-primary;
  }
  &.field-icon{
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  &.inner-field-icon{
    position: absolute;
    left: 5px;
    bottom: 3px;
    z-index: 1;
    & + input{
      padding-left: 30px;
    }
  }
  &.dashboard{
    background-position: -9px -142px;
    width: 18px;
    height: 18px;
    .active &{
      background-position: -9px -73px;
    }
  }
  &.templates{
    background-position: -76px -142px;
    width: 18px;
    height: 18px;
    .active &{
      background-position: -76px -73px;
    }
  }
  &.campaigns{
    background-position: -144px -143px;
    width: 18px;
    height: 18px;
    .active &{
      background-position: -144px -73px;
    }
  }
  &.clients{
    background-position: -212px -141px;
    width: 18px;
    height: 18px;
    .active &{
      background-position: -212px -72px;
    }
  }
  &.arrow-down{
    background-position: -199px -15px;
    width: 11px;
    height: 6px;
  }
  &.settings{
    background-position: -259px -8px;
    width: 15px;
    height: 16px;
  }
  &.calendar{
    background-position: -9px -209px;
    width: 16px;
    height: 19px;
  }
  &.search{
    background-position: -76px -209px;
    width: 16px;
    height: 20px;
  }
  &.plus{
    background-position: -143px -208px;
    width: 16px;
    height: 16px;
  }
  &.excluded{
    background-position: -9px -275px;
    width: 16px;
    height: 16px;
    &.active{
      background-position: -9px -345px;
    }
  }
  &.conversation{
    background-position: -76px -275px;
    width: 16px;
    height: 16px;
    &.active{
      background-position: -76px -345px;
    }
  }
  &.voucher{
    background-position: -142px -275px;
    width: 16px;
    height: 16px;
    &.active{
      background-position: -142px -345px;
    }
  }
  &.paid{
    background-position: -208px -274px;
    width: 20px;
    height: 20px;
    &.active{
      background-position: -208px -344px;
    }
  }
  &.points{
    background-position:-400px -6px;
    width: 20px;
    height: 20px;
  }
  &.back{
    background-position:-137px -475px;
    width: 20px;
    height: 20px;
  }
  &.card-grid{
    background-position: -307px -479px;
    width: 15px;
    height: 16px;
    .active &{
      background-position: -262px -479px;
    }
  }
  &.table-grid{
    background-position: -399px -479px;
    width: 15px;
    height: 16px;
    .active &{
      background-position: -354px -479px;
    }
  }
  &.send{
    background-position: -445px -480px;
    width: 16px;
    height: 16px;
  }
}

.icon-element-1 {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-image: url('../images/sprites-5.svg');
  background-repeat: no-repeat;
  background-position: center center;
  speak: none;
  &.templates-2{
    width: 19px;
    height: 19px;
    .active &{
      // background-position: -76px -73px;
    }
  }&.templates-3{
    width: 18px;
    height: 18px;
    left: 4px;
    .active &{
      // background-position: -76px -73px;
    }
  }
}

.logo-icon{
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}

/*------------------------------------------------------------------------------Forms */
input, select, textarea {
  @include appearance(none);
  display: block;
  width: 100%;
  background-color: transparent;
  padding: 10px;
  transition: all 350ms ease;
  height: 30px;
  background-color: #FFF;
  font-size: #{(12 / $font-size-base)}rem;
  font-family: $font-family-base;
  background-color: #FFF;
  border: 0 none;
  border-bottom: 1px solid $input-border-color;
  transition: all 350ms ease;
  &.inline-field{
    display: inline-block;
    width: auto;
    min-width: 100px;
  }
  &:hover{
    border-color: $primary;
  }
  &:focus {
    outline: 0 none;
    border-color: $primary;
    background-color: lighten($border-color, 20%);
  }
  &.bordered{
    border: 1px solid $border-color;
    &:hover{
      border-color: $primary;
    }
  }
}

input[type="radio"], input[type="checkbox"]{
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid $border-color;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  &:checked{
    background-color: $primary;
  }
}

input[readOnly]{
  background-color: lighten($border-color, 20%);
}

input[type="checkbox"]{
  border-radius: 0;
}

textarea{
  min-height: 130px;
  min-width: 300px;
  border: 1px solid $border-color;
  border-radius: 5px;
}

select{
  padding: 5px;
  padding-right: 25px;
  position: relative;
  background-image: url('../images/selector.svg');
  background-position: 99% 11px;
  background-repeat: no-repeat;
  font-size: #{(12 / $font-size-base)}rem;
  border-radius: 0;
  &.filter-field{
    background-position: 95% 7px;
  }
}

.invalid-input{
  input{
    border-color: $red !important;
  }
}

label{
  display: block;
  position: relative;
  margin-bottom: 30px;
  &:last-child{
    margin-bottom: 0;
  }
}

.label-like{
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
  font-size: #{(12 / $font-size-base)}rem;
  .invalid-input &{
    color: $red;
  }
}

.checkbox-holder{
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  vertical-align: middle;
  input{
    display: none;
    &:checked{
      & + .slider{
        background-color: $green;
        &:before{
          transform: translateX(26px);
        }
      }
    }
  }
  .slider{
    width: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    padding: 17px;
    transition: .4s;
    border-radius: 34px;
    &:before{
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 100%;
    }
  }
  & + .label-like{
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-bottom: 0;
    width: calc(100% - 65px);
  }
}

.button-input{
  input{
    width: calc(100% - 30px);
    display: inline-block;
  }
  .btn{
    width: 30px;
    height: 30px;
    padding: 0;
  }
}

.error-message{
  font-size: #{(12 / $font-size-base)}rem;
  color: $red;
}

.form-button-holder{
  border-top: 1px solid $border-color;
  padding: 10px;
  position: absolute;
  width: 100%;
  background-color: white;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
}

.filter-field{
  border: 1px solid $border-color;
  border-radius: 5px;
}

.step-list{
  display:table;
  padding: 0;
  width: 100%;
  max-width: 55%;
  list-style: none;
  counter-reset: item;
  padding: 10px;
  margin: 0 auto;
  &.full{
    width: 100%;
    max-width: 100%;
  }
  &.inner{
    display: block;
    padding: 0px;
    li{
      padding: 15px 30px;
      display: block;
      border-bottom: 0;
      margin-bottom: 0;
      h4{
        font-weight: bold;
        margin-bottom: 10px;
        color: #9c9b9b;
        font-size: #{(14 / $font-size-base)}rem;
      }
    }
  }
   > li{
    display: table-cell;
    counter-increment: item;
    position: relative;
    padding: 10px 30px;
    border-bottom: 2px solid $border-color;
    a{
      color: $input-border-color;
      text-decoration: none;
      display: block;
      text-align: center;
      font-size: #{(12 / $font-size-base)}rem;
      &.confirm-title{
        color: $primary;
        text-decoration: underline;
      }
    }
    &:last-child{
      a{
        border-right: 0 none;
      }
    }
    &.active{
      border-color: $primary;
      a{
        font-weight: bold;
        color: $primary;
      }
      &:before{
        background-color: $primary
      }
    }
    &.success{
      border-color: $green;
      a{
        font-weight: bold;
        color: $green;
      }
      &:before{
        background-color: $green
      }
    }
  }
  @include respond-to('xlarge'){
    max-width: 45%;
  }
}

.steps-panel-holder{
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: top;
  padding: 20px 0;
}

.step-form-panel{
  border-left: 1px solid $border-color;
  &.active{
    padding: 50px 30px;
  }
}

.field-disclaimer{
  font-size: #{(12 / $font-size-base)}rem;
  color: #A7A7A7;
  font-style: italic;
  display: block;
  margin-top: 5px;
}

.upload-button-holder{
  .btn{
    border-radius: 5px 0 0 5px;
  }
  .fake-input{
    border-bottom: 1px solid $border-color;
    display: inline-block;
    width: calc(100% - 165px);
    vertical-align: top;
    padding: 7px;
    border-radius: 0;
    white-space: nowrap;
    font-size: #{(12 / $font-size-base)}rem;
  }
}

.day{
  font-size: #{(11 / $font-size-base)}rem;
  border-radius: 100%;
  text-align: center;
  width: 24px;
  height:24px;
  border: 1px solid $input-border-color;
  background-color: transparent;
  display: inline-block;
  margin-right: 5px;
  color: $input-border-color;
  text-transform: uppercase;
  padding: 0;
  line-height: 200%;
  &:focus{
    outline: 0 none;
  }
  &.active{
    border-color: $secondary;
    background-color: $secondary;
    color: $primary;
  }
  &:last-child{
    margin-right: 0;
  }
}

.form-wrapper {
  max-width: 70%;
  margin: 0 auto;
  &.big{
    max-width: 90%;
  }
}

.inline-field{
  display: inline-block;
  width: auto;
  margin-right: 10px;
}
/*------------------------------------------------------------------------------Backgrounds */

.gradient-blue-bg{ 
  background: linear-gradient(to bottom, #276AA6 0%,#143553 100%);
 }

 .gray-bg{
   background-color: $body-bg;
 }

/*------------------------------------------------------------------------------Header */

.logo{
  font-size: 0;
  color: transparent;
  img{
    max-width: 260px;
  }
}

.user-navigation{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  span{
    margin-right: 20px;
  }
  .dropdown-holder{
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    right: 0;
  }
}

/*------------------------------------------------------------------------------Containers */

.box{
  background-color: #FFFFFF;
  // border: 1px solid $border-color;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.3);
  padding: 20px;
  &.inner{
    padding: 0;
    margin-bottom: 25px;
  }
  &.tab-box{
    border-radius: 0 0 5px 5px;
  }
  &.no-shadow{
    box-shadow: none;
  }
  &.no-padding{
    padding: 0;
  }
  &.box-form{
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    @include respond-to('xlarge'){
      max-width: 50%;
    }
  }
  &.table-view{
    display: table;
    // table-layout: fixed;
    width: 100%;
    margin-bottom: 5px;
    padding: 0;
    border-radius: 0 5px 5px 0px;
    .campaign-box-header-indicator{
      position: static;
      padding-top: 15px;
    }
    .campaign-box-wrapper{
      display: table-cell;
      vertical-align: middle;
      border-bottom: 0 none;
      border-right: 1px solid $border-color;
      width: 25%;
      // padding: 8px;
      &:last-child{
        border: 0 none;
      }
      h4{
        display: none;
      }
    }
  }
}

.campaign-table{
  position: relative;
  padding-left: 30px;
  padding-top: 15px;
  &:before{
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 0;
    border-right: 40px solid transparent;
  }
  &.success{
    &:before{
      border-top: 40px solid lighten($green, 25%);      
    }
  }
  &.inactive{
    &:before{
      border-top: 40px solid lighten($text-color, 50%);      
    }
  }
  &.finished{
    &:before{
      border-top: 40px solid lighten($primary, 40%);      
    }
  }
  &.error{
    &:before{
      border-top: 40px solid lighten($red, 20%);      
    }
  }
}

.campaign-box{
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
  padding: 0;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer;
  transition: all 350ms ease;
  &:hover{
    box-shadow: 0px 10px 20px rgba(0,0,0,0.3);
    transform: scale(1.05,1.05);
  }
  &.success{
    .campaign-box-header:before{
      background-color: lighten($green, 25%);    
    }
  }
  &.inactive{
    .campaign-box-header:before{
      background-color: lighten($text-color, 50%);    
    }
  }
  &.finished{
    .campaign-box-header:before{
      background-color: lighten($primary, 40%);     
    }
  }
  &.error{
    .campaign-box-header:before{
      background-color: lighten($red, 20%);   
    }
  }
  .campaign-box-header{
    position: relative;
    margin-bottom: 8px;
    &:before{
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0;
      left: 0px;
      width: 5px;
    }
  }
}

.campaign-box-wrapper{
  padding: 6px;
  padding-left: 30px;
  padding-right: 30px;
  // border-bottom: 1px solid $border-color;
  &:last-child{
    border: 0 none;
  }
 
}

// .aside{
//   margin-bottom: 200px;
// }

.campaign-box-header{
  padding: 10px 20px;
  border-bottom: 1px solid $border-color;
  h2{
    margin-bottom: 0;
    font-size: #{(14 / $font-size-base)}rem;
  }
}

.campaign-box-header-indicator{
  position: absolute;
  top: 10px;
  right: 10px;
  color: $primary;
  font-size: #{(12 / $font-size-base)}rem;
  font-weight: 500;
  &.static{
    position: static;
  }
}

.campaign-client-visualization-header {
  font-size: #{(12 / $font-size-base)}rem;
  font-weight: 500;
  position: relative; 
  .sms,
  .whatsapp,
  .email,
  .voz {
    display: inline-block;
    position: absolute;
    width: 22px;
    height: 22px;
    background-size: cover;
    background-repeat: no-repeat;
    top: -4px;
   
    left: 92%;
    // left: calc(90% - -140px);
   
  }
  
  .sms {
    background-image: url('../images/sms.png');
  }
  
  .whatsapp {
    background-image: url('../images/whatsaap.png');
  }
  
  .email {
    background-image: url('../images/email.png');
  }
  
  .voz {
    background-image: url('../images/voz.png');
  }
}

.percent-bar-holder{
  position: relative;
  height: 30px;
  margin-top: 10px;
  top: -8px;
  width: 85%; 
}

.percent-bar{
  height: 1px;
  width: 100%;
  position: absolute;
  top: -10px;
  background-color: $percent-bar-color;
  display: flex; 
  align-items: center; 
}

.percent-bar-completed{
  width: 80%; 
  display: block;
  height: 5px;
  background-color: $percent-bar-completed-color;
  position: absolute;
  border-radius: 5px;
}

.percent-bar-values-holder{
  margin: 10px 0;
  position: relative;
  position: absolute;
  width: 100%;
  height: 10px;
}

.percent-bar-value{
  font-weight: bold;
  color: $border-color;
  position: absolute;
  right: 50px;
  &.right{
    left: auto;
    right: 0;
    color: #276AA6;
  }
}

.processing-text {
  position: absolute;
  left: 30%;
  color:   #f4ac06  ;
  font-weight: bold;
}
.cancel-text {
  position: absolute;
  left: 30%;
  color:  #e4097f;
}

.completed-text {
  position: absolute;
  left: 30%;
  color:   #88b72c ;
  font-weight: bold;
  transition: background-color 0.3s ease;
}



@keyframes example-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.send-text {
  position: absolute;

  left: 30%;
  color:  #6d6e70;
  font-weight: bold;
}

.box-bar{
  width: 100%;
  position: relative;
  top: 50%;
}

/// cards en la grilla

.card {
  display: flex;
  flex-direction: column;
  width: 100%; 
  // padding-top: 10px;
  border-radius: 15px;
  background-color: #F4F5F4;
  margin-bottom: 20px;
}
.texto-columna {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1px;
  margin-top: 15px;
}

.texto-columna p {
  margin: 0;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 14px;
}


.texto-container {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(4, auto);
  gap: 5px;
  grid-column-gap: 19px;
  
  margin-left: 12%;
}

.texto-elemento {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.texto-titulo {
  font-family: "Geomanist";
  font-weight: bold; 
  font-size: 19px;
  color: #2ca0bd

}

.texto-valor {
  margin: 0;
  margin-left: auto;
  // font-family: "Montserrat", Arial, sans-serif; 
  font-size: 12px; 
//   font-style:  italic;
}

.texto-valor-hora{
  margin: 0;
  margin-left: 10%; 
  font-size: 12px;
}


//css preloader

.secondary-data {
  color: $border-color;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  font-size: #{(12 / $font-size-base)}rem;
  font-weight: 500;
  position: relative;
  background-color: #F4F5F4;
  border-radius: 5%;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}


.element-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.element-container p {
  margin-left: 20px;
}

.element-container hr {
  margin: 0;
  border: none;
  border-top: 1px solid $border-color;
}
.icono {
  display: inline-block; 
  vertical-align: middle; 
  background-size: contain; 
  background-repeat: no-repeat; 
  width: 20px; 
  height: 20px; 

  
  &.inicio {
    background-image: url('../images/inicio.png');
  }
  &.termino {
    background-image: url('../images/termino.png');
  }
  &.carga {
    background-image: url('../images/carga.png');
  }
  // &.envio {
  //   background-image: url('../images/envio.png');
  // }
  &.reloj {
    background-image: url('../images/reloj.png');
  }
}





.texto-inicio,
.texto-termino,
.texto-carga,
.texto-envio {
  font-size: small;
  font-weight: bold;
  color: black;
}


@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .carga,
  .envio,
  .termino,
  .inicio {
    position: relative;
    width: auto;
    height: auto;
    margin-bottom: 10px;
  }

  .texto-creacion,
  .texto-envio,
  .texto-inicio,
  .texto-termino {
    position: relative;
    font-size: small;
    margin-left: 30px;
  }
}


.percent-bar-holder{
  position: relative;
  height: 30px;
  margin-top: 10px;
}

.percent-bar{
  height: 25px;
  border-radius: 30px;
  width: 100%;
  position: absolute;
  top: 8px;
  background-color:  #F4F5F4;
}

.percent-bar-completed{
  width: 50%;
  display: block;
  height: 25px;
  background-color: $percent-bar-completed-color;
  position: absolute;
  // top: -1px;
  border-radius: 30px;
}

.percent-bar-values-holder{
  margin: 10px 0;
  position: relative;
  position: absolute;
  width: 100%;
  height: 30px;
}

.percent-bar-value{
  font-weight: bold;
  color: black;
  position: absolute;
  left: 0;
  top: 10%;
  &.right{
    // left: auto;
    right: -2%;
  }
}

///// color barra progreso
.gray {
  background-color: gray;
}

.yellow {
  background-color: yellow;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.intermittent-effect {
  animation: blink-animation 7s infinite;
}




.fieldset-box{
  border-bottom: 1px solid $border-color;
  padding-bottom: 20px;
  margin-bottom: 20px;
  &:last-child{
    border: 0 none;
    margin-bottom: 0;
  }
}

.box-small-centered{
  max-width: 450px;
  margin: 0 auto;
}

.inner-content{
  position: relative;
  padding: 0 40px 20px 40px;
}

.inner-header-content-holder{
  display: inline-block;
}

.inner-header{
  background-color:$body-bg;
  // border-bottom: 1px solid $border-color;
  padding: 20px 0;
  // margin-bottom: 20px;
  position: relative;
  &.with-tabs{
    padding-bottom: 50px;
  }
  &:before{
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 5px;
  }
  &.no-margin{
    margin-bottom: 0;
  }
}

.t-white{
  color: #FFF;
}

.kpi{
  padding: 20px;
  display: inline-block;
  border-right: 1px solid $border-color;
  &:last-child{
    border-right: 0;
  }
}

.kpi-value{
  font-weight: bold;
  font-size: #{(24 / $font-size-base)}rem;
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

.kpi-key{
  color: $lighter-text-color
}

.not-found-box{
  text-align: center;
  margin: 30px auto;
  width: 100%;
  max-width: 50%;
  p{
    color: $not-found-text-color;
    font-size: #{(18 / $font-size-base)}rem;
  }
}

.scrolled-box{
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  &.inner{
    max-height: 305px;
  }
  &.big{
    max-height: 410px;
  }
  &.bigger{
    max-height: 500px;
  }
  &.settings{
    max-height: calc(100vh - 180px);
  }
}

.scrolled-content{
  max-height: calc(100vh - 90px);
  overflow-x: hidden;
  overflow-y: scroll;
  &.inner{
    padding: 30px 30px 100px 30px;
  }
}

.min-box{
  min-height: 400px;
}

.detail-list-holder{
  min-height: 100px;
  position: relative;
}

.detail-list{
  box-shadow: none;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid $border-color;
  border-top: 0 none;
  list-style: none;
  background-color: #FFF;
  margin-bottom: 20px;
  li{
    a{
      display: block;
      padding: 10px;
      color: $border-color;
      font-weight: normal;
      font-size: #{(12 / $font-size-base)}rem;
      text-decoration: none;
      border-bottom: 1px solid $border-color;
      &.active{
        color: $text-color;
        font-weight: 500;
      }
      &:hover{
        color: $text-color;
        background-color: darken(#FFF,5%)
      }
    }
    &:last-child{
      a{
        border: 0 none;
      }
    }
  }
}

.badge{
  color: #FFF;
  background-color: $yellow;
  font-size: #{(14 / $font-size-base)}rem;
  text-align: center;
  display: inline-block;
  padding: 0px 5px;
  vertical-align: middle;
  height: 24px;
  border-radius: 5px;
  margin-left: 10px;
  &.small{
    line-height: 175%;
    height: 20px;
    padding: 0 5px;
    font-size: #{(12 / $font-size-base)}rem;
    margin-left: 0;
  }
}

.label{
  display: inline-block;
  margin-right: 5px;
  background-color: $primary;
  color: #FFF;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  &.warning{
    background-color: $light-yellow;
    color: $yellow;
  }
}
/*------------------------------------------------------------------------------Loaders */

.box-loader{
  position: absolute;
  background-color: rgba(255,255,255,0.5);
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.loader {
  border: 3px solid #cccccc;
  border-top: 3px solid $primary;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
}

/*------------------------------------------------------------------------------Screen */

.screen{
	background-color:rgba(255,255,255,0.7);
	position: fixed;
  z-index: 9999999;
	left: 0;
	right:0;
	bottom: 0;
  top: 0;
  overflow: scroll;
}

.modal{
	background-color: #FFF;
	max-width: 900px;
	margin: 0 auto;
	top: 10px;
	left:0;
	right: 0;
	position: absolute;
	width: 100%;
	padding: 0;
	&.modal-small{
		max-width: 750px;
  }
  &.modal-smallest{
    max-width: 450px;
  }
  &.modal-min-height{
    min-height: calc(100vh - 50px);
    height: auto;
  }
  &.h-100{
    height: 100%;
  }
}

.modal-header{
	border-bottom: 1px solid $border-color;
	padding: 20px 30px;
	h3{
		margin-bottom: 0;
		text-transform: none;
		font-size: #{(16 / $font-size-base)}rem;
	}
}

.iframe-demo{
  border: 0 none;
  height: 550px;
  width: 100%;
}

/*------------------------------------------------------------------------------Disclaimers */

.disclaimer{
  border-radius: 5px;
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0;
  }
  &.error, &.errors{
    border: 1px solid $red;
    color: $red;
    background-color: $light-red;
    a{
      color: $red
    }
  }
  &.success{
    border: 1px solid $green;
    color: $green;
    background-color: $light-green;
    a{
      color: $green
    }
  }
  &.warning{
    border: 1px solid $yellow;
    color: $yellow;
    background-color: $light-yellow;
    a{
      color: $yellow
    }
  }
  &.floated{
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
  }
  p {
    padding: 20px;
  }
  .disclaimer-list{
    list-style: none;
    border-top: 1px solid;
    li{
      padding: 10px;
      border-bottom: 1px solid;
      &:last-child{
        border: 0 none;
      }
      .warning &{
        border-color: $yellow;
      }
    }
    .warning &{
      border-color: $yellow;
    }
  }
}
/*------------------------------------------------------------------------------Tables */
table {
  width: 100%;
  tr{
    &:hover{
      .table-action-buttons-holder{
        opacity: 1;
        visibility: visible;
      }
      td{
        background-color: darken(#FFF, 1%);
      }
    }
  }
  th{
    font-weight: normal;
    color: $table-header-color;
    text-align: left;
    padding: 15px 20px;
    white-space: nowrap;
  }
  td{
    padding: 15px 20px;
    vertical-align: top;
    border-top: 1px solid $border-color;
    transition: all 350ms ease;
  }
  &.small{
    th, td{
      padding: 10px;
    }
  }
  &.no-padding{
    th{
      padding: 15px;
    }
    td{
      padding: 0;
    }
  }
  &.cursored{
    td{
      &:hover{
        cursor: pointer;
      }
    }
  }
}

.table-wrapp{
  padding: 10px;
}

.table-action-buttons-holder{
  white-space: nowrap;
}


/*------------------------------------------------------------------------------Dropdown */
.dropdown-holder{
  position: absolute;
  right: 20px;
  top: 6px;
  z-index: 99999;
}

.dropdown{
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  min-width: 250px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 350ms ease;
  border-radius: 5px 0px 5px 5px;
  z-index: 1;
  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  ul{
    list-style: none;
    li{
      padding: 0;
      border-bottom: 0 none;
      a{
        display: block;
        border-bottom: 1px solid $border-color;
        text-decoration: none;
        padding: 20px;
      }
      &:last-child{
        a{
          border-bottom: 0 none;
        }
      }
    }
  }
}

/*------------------------------------------------------------------------------Datepicker */
.react-datepicker__input-container{
  input{
    padding-left: 30px;
  }
}

.react-datepicker-popper{
  z-index: 2;
}

.react-datepicker__navigation{
  font-size: 0;
  color: transparent;
  line-height: 0;
}
/*------------------------------------------------------------------------------Tabs */
.tabs-holder{
  width: 100%;
  position: relative;
}

.tabs-buttons-holder{
  position: absolute;
  bottom: calc(100% + 20px);
  &.inner{
    position: static;
    .btn-tab{
      border-bottom: $body-bg;
      // border-bottom: black;
      &.active{
        background-color: #FFF; 
        border-bottom: 0 none;
        &:after{
          background-color: #FFF;
        }
      }
    }
  }
  &.small{
    .btn-tab{
      font-size: #{(12 / $font-size-base)}rem;
      padding: 10px 15px;
    }
  }
  &.horizontal-padding{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.tab-panel{
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: opacity 300ms ease;
  &.active{
    opacity: 1;
    visibility: visible;
    max-height: none;
  }
}
/*------------------------------------------------------------------------------Calendar */

.definition{
  width: 70%;
  .calendar-holder{
    width: 60%;
  }
}

.calendar-holder{
  overflow: hidden;
  margin-bottom: 20px;
  border-bottom: 1px solid $border-color;
  padding-bottom: 20px;
  &:last-child{
    border: 0 none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.calendar-title{
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 5px;
  display: inline-block;
  font-size: #{(12 / $font-size-base)}rem;
}

.calendar-table{
  width: 100%;
  thead{
    tr{
      th{
        font-size: #{(12 / $font-size-base)}rem;
        padding: 17px;
      }
    }
  }
  tbody{
    tr{
      td{
        text-align: center;
        font-size: #{(12 / $font-size-base)}rem;
        padding: 5px;
        border: 0 none;
        border-bottom: 1px solid #FFF;
        &.active{
          background-color: $secondary;
          color: $primary;
          &.past-day{
            background-color: lighten($yellow, 25%);
            color: $text-color;
          }
        }
        &.selected{
          position: relative;
          &:after{
            content: "";
            background-color: $yellow;
            display: block;
            border-radius: 100%;
            width: 5px;
            height: 5px;
            position: absolute;
            top: -3px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
        &.today{
          background-color: $primary;
          color: #FFF;
        }
      }
    }
  }
}

.conversation-bubble{
  border-radius: 5px;
  padding: 10px;
  color: $primary;
  margin-right: 10%;
  margin-bottom: 20px;
  &.in{
    background-color: $secondary;
    margin-left: 10%;
    margin-right: 0;
  }
  p{
    margin-bottom: 0px;
  }
}

/*------------------------------------------------------------------------------Header */
.main-header{
  padding: 0 20px;
  border-bottom: 1px solid $border-color;
  background-color: #FFF;
  position: relative;
}

.header-logo{
  display: inline-block;
  max-width: 270px;
}

.main-navigation{
  display: inline-block;
  margin-left: 65px;
  a{
    display: inline-block;
    padding: 15px 20px;
    color: $navigation-titles;
    font-size: #{(14 / $font-size-base)}rem;
    text-decoration: none;
    transition: background 300ms ease;
    &:hover{
      background-color: darken(#FFF, 2%)
    }
    &.active{
      font-weight: 500;
      position: relative;
      &:after{
        content: "";
        height: 3px;
        background-color: $primary;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -2px;
      }
    }
    .icon-element{
      margin-right: 15px;
    }
  }
}


.header-actions-holder{
  position: absolute;
  top: -5px;
  right: 20px;
}

/*------------------------------------------------------------------------------ASISTENTE */
.assistant-holder{
  position: relative;
  border: 1px solid $border-color;
}

.assistant-main-header{
  padding: 10px;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0 3px 6px rgba(0,0,0,.1);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
  .whatsapp &{
    background-color: #005e55 !important;
  }
}

.assistant-main-header-col {
  display: inline-block;
  vertical-align: middle;
  &.left {
    width: 50px;
    margin-right: 10px;
  }
  &.right{
    width: calc(100% - 65px);
  }
}

.assistant-title{
  margin-bottom: 0;
  font-size: #{(16 / $font-size-base)}rem;
  text-transform: none;
}

.assistant-subtitle{
  font-size: #{(13 / $font-size-base)}rem;
  .whatsapp &{
    display: none
  }
}

.assistant-conversation-holder{
  background-color: #FFF;
  padding: 80px 10px 10px 10px;
  height: calc(100vh - 180px);
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 100px;
  position: relative;
  .error-assistant{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatsapp &{
    position: relative;
    background-color: #efe9e7 !important;
    &:before{
      content: "";
      position: absolute;
      background: url('../images/whatsapp-bg.png') repeat #efe9e7 !important;
      opacity: 0.2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
}

.assistant-input-user-holder{
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e7e7e7;
}

.assistant-form-wrapp{
  transition: all .3s ease;
  background-color: #FFF;
}

.assistant-input-user{
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 45px);
  margin-bottom: 0;
  height: 50px;
  border-bottom: 0 none;
}

.assistant-action-button-holder{
  display: inline-block;
  position: relative;
  &:hover{
    .assistant-btn-light{
      &+ .assistant-tooltip{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.assistant-btn{
  font-family: Open sans,sans-serif;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  width: auto;
  height: auto;
  line-height: 120%;
  transition: background .3s ease;
  color: #fff;
  transition: all .35s ease;
  border-radius: 10px;
  border: 0 none;
  margin-right: 10px;
  margin-bottom: 10px;
  &:last-child{
    margin-right: 0;
  }
  &.assistant-btn-light{
    background-color: transparent;
    border: 1px solid;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    padding: 0;
    box-shadow: none;
  }
  .whatsapp &{
    background-color: #00897b !important;
    border-color: #00897b !important;
  }
}

.assistant-tooltip{
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  bottom: calc(100% + 5px);
  white-space: nowrap;
  right: 0;
  transition: opacity .3s ease;
}

.assistant-spinner-holder{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #FFF;
  height: 100%;
}

.assistant-spinner{
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 10px;
  position: relative;
  top: calc(50% - 25px);
  margin: 0 auto;
  text-indent: -9999em;
  border-top: 3px solid rgba(0, 0, 0, 0.2);
  border-right: 3px solid rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  border-left: 3px solid rgba(0, 0, 0, 0.2);;
  transform: translateZ(0);
  animation: spinner 1.1s infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.assistant-conversation-bubble{
  margin-bottom: 15px;
  &.to{
    text-align: right;
  }
}

.assistant-bubble{
  display: inline-block;
  background-color: #e7e7e7;
  padding: 15px;
  border-radius: 30px;
  margin-top: 3px;
  max-width: calc(100% - 70px);
  color: #6b6a6f;
  font-size: .875rem;
  .whatsapp &{
    max-width: calc(100% - 10px);
    background-color: #FFF !important;
    border-radius: 0px 5px 5px 5px;
    box-shadow: 0px 2px 1px rgba(0,0,0,0.2);
    color: #1e281e !important;
  }
}

.to .assistant-bubble{
  text-align: left;
  .whatsapp &{
    background-color: #e1ffc8 !important;
    border-radius: 5px 0px 5px 5px;
  }
}

.assistant-response-image{
  display: inline-block;
  vertical-align: top;
  width: 50px;
  margin-right: 10px;
  .whatsapp &{
    display: none;
  }
}

.assistant-loader {
	text-align:center;
	width:100px;
  position: absolute;
  bottom: 100%;
  height: 30px;
  margin: 0 auto;
  left: 0;
  right: 0;
	.dot {
		display:inline-block;
		width:12px;
		height:12px;
		border-radius:50%;
		margin-right:3px;
		animation: wave 1.3s linear infinite;
		&:nth-child(2) {
			animation-delay: -1.1s;
		}
		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-15px);
	}
}

.calendar-assistant-holder{
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  max-width: 350px;
  border: 1px solid #e7e7e7;
  margin-right: 10px;
}

.calendar-assistant-title{
  font-weight: bold;
  text-align: center;
  display: block;
  margin-bottom: 5px;
}

.calendar-assistant-table{
  th{
    font-weight: bold;
    color: #6b6a6f;
  }
  td{
    color: #e7e7e7;
    &.active{
      font-weight: bold;
    }
  }
  td, th{
    padding: 5px;
    font-size: #{(12 / $font-size-base)}rem;
    border-bottom: 1px solid #e7e7e7;
  }
  tr:last-child{
    td{
      border-bottom: 0 none;
    }
  }
}

.step-form-channel-menu{
  list-style: none;
  padding: 0;
  width: 17%;
  display: inline-block;
  vertical-align: top;
  li{
    padding: 20px;
    border-bottom: 1px solid $border-color;
    color: $input-border-color;
    position: relative;
    cursor: pointer;
    transition: all 300ms ease;
    font-size: #{(12 / $font-size-base)}rem;
    &.active, &:hover{
      color: $primary;
      font-weight: bold;
    }
  }
}

.step-form-channel-title{
  font-weight: bold;
}

.step-form-channel-menu-check{
  position: absolute;
  top: 14px;
  right: 20px;
}

.step-form-channel-content{
  width: 83%;
  display: inline-block;
  vertical-align: top;
  border-left: 1px solid $border-color;
  min-height: 300px;
  position: relative;
}

.draftJsToolbar__toolbar__dNtBH:before,
.draftJsToolbar__toolbar__dNtBH:after{
  display: none;
}

.mention-suggestions > div {
  max-height: 250px;
  overflow: scroll;
}

.color-picker{
  border: 1px solid $border-color;
  .color-box{
    display: inline-block;
    width: 20px; 
    height: 20px;
    vertical-align: middle;
    margin: 5px;
  }
  .color-box-text{
    display: inline-block;
    width: calc(100% - 30px);
    border-left: 1px solid $border-color;
    vertical-align: middle;
    padding: 10px;
  }
}

.color-picker-holder{
  .sketch-picker{
    position: absolute;
    z-index: 1;
  }
}

.toolbar{
  width: 20%;
  position: fixed;
  padding: 20px;
  left: 0;
  height: calc(100vh - 0px);
  background-color: #FFF;
  background-color: #19405d ;
  padding-left: 45px;
  border-right: 1px solid $border-color;
}

.content-with-toolbar{
  width: 80%;
  margin-left: 20%;
}

.content-with-toolbar-scrolled{
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 190px);
}

.content-tool-bar{
  padding: 10px 20px;
  border-bottom: 1px solid $border-color;
}

.character-counter{
  float: right; 
  font-weight: normal;
}
